/* eslint-disable no-template-curly-in-string */

export type ProjectEnvVariablesType = {
  [K in keyof ImportMetaEnv]: string;
};

// Environment Variable Template to Be Replaced at Runtime
const projectEnvVariables: ProjectEnvVariablesType = {
  VITE_API_URL: '${VITE_API_URL}',
  VITE_AUTH_AUDIENCE: '${VITE_AUTH_AUDIENCE}',
  VITE_AUTH_DOMAIN: '${VITE_AUTH_DOMAIN}',
  VITE_DD_CLIENT_TOKEN: '${VITE_DD_CLIENT_TOKEN}',
  VITE_ENV: '${VITE_ENV}',
  VITE_FIX_LOGIN: '${VITE_FIX_LOGIN}',
  VITE_NEW_LOGIN_FLOW: '${VITE_NEW_LOGIN_FLOW}',
  VITE_PENDO_API_KEY: '${VITE_PENDO_API_KEY}',
  VITE_TEMPORAL: '${VITE_TEMPORAL}',
  VITE_WEBHOOK_URL: '${VITE_WEBHOOK_URL}',
  VITE_WEBSERVER_API_URL: '${VITE_WEBSERVER_API_URL}',
  VITE_VERSION: '${VITE_VERSION}',
  BASE_URL: '${BASE_URL}',
  DEV: '${DEV}',
  MODE: '${MODE}',
  PROD: '${PROD}',
  SSR: '${SSR}',
  STORYBOOK: '${STORYBOOK}',
  VITE_SHARED_WORKER: '${VITE_SHARED_WORKER}',
  VITE_SKIP_AUTH: '${VITE_SKIP_AUTH}',
};

export default projectEnvVariables;
